<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="插件名称">
        <a-input v-model:value="formState.title" placeholder="请输入插件名称" />
      </a-form-item>
      <a-form-item label="插件标识" extra="标识使用英文小写">
        <a-input v-model:value="formState.name" placeholder="请输入插件标识" />
      </a-form-item>
      <a-form-item label="插件类型">
        <a-select v-model:value="formState.type">
          <a-select-option value="system">系统类</a-select-option>
          <a-select-option value="promotion">营销类</a-select-option>
          <a-select-option value="tool">工具类</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="模式">
        <a-radio-group v-model:value="formState.model">
          <a-radio value="1">模块</a-radio>
          <a-radio value="2">依赖</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="依赖模块">
        <a-select
          v-model:value="formState.depend"
          :options="options"
          mode="tags"
          :size="size"
          placeholder="请选择依赖项"
        ></a-select>
      </a-form-item>
      <a-form-item label="依赖插件">
        <a-select
          v-model:value="formState.depend"
          :options="options"
          mode="tags"
          :size="size"
          placeholder="请选择依赖项"
        ></a-select>
      </a-form-item>
      <a-form-item label="插件图标">
        <CsImage v-model:imgOne="formState.icon" />
      </a-form-item>
      <a-form-item label="版本号">
        <a-input v-model:value="formState.version" placeholder="请输入版本号" />
      </a-form-item>
      <a-form-item label="作者">
        <a-input v-model:value="formState.author" placeholder="请输入作者" />
      </a-form-item>
      <a-form-item label="插件描述">
        <a-textarea
          v-model:value="formState.description"
          placeholder="请输入插件描述"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, toRaw } from "vue";
import CsImage from "@/components/UpImage";

export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    const formState = reactive({
      title: "",
      name: "",
      type: "system",
      icon: "",
      model: "1",
      depend: ["会员", "支付"],
      version: "1.0.0",
      author: "Crate官方",
      description: "",
    });

    const onSubmit = () => {
      console.log("submit!", toRaw(formState));
    };

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
      options: [...Array(25)].map((_, i) => ({
        value: (i + 10).toString(36) + (i + 1),
      })),
    };
  },
});
</script>